$blue: #263764;
@import './assets/styles/breakpoints';

a {
  color: white;
  text-decoration: underline;

  &:hover {
    color: seagreen;
  }
  // &:visited {
  //   color: white;
  // }
  &:focus {
    outline: 2px solid seagreen;
    outline-offset: 10px;
  }
}
body {
  background-color: $blue;
  font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-weight: 600;
}
.main {
  text-align: center;
  width: 100%;
  height: auto;
  overflow: hidden;
  padding-bottom: 25px;
  a {
    margin-bottom: 25px;
  }
  img {
    position: relative;
    margin: 50px auto 50px auto;
    // padding: 0 125px;
    display: flex;
    width: 100%;
    max-width: 80%;
  }
  p {
    margin: 10px 0 0 0;
  }
  @include medium-up {
    img {
      // margin: 50px auto 0 auto;
      width: 400px;
    }
  }
}
.copy {
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
}
/*
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
*/
